import styled from "styled-components";
import {  cardMixin , innerCardLefttMixin} from "../mixin"; 
import { Modulo4PisosGrid } from "../mixinContainer4Pisos"; 
import { Modulo2PisosGrid } from "../mixinContainer2Pisos"; 
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";
export const GranParent =styled.div `
  border-top: 1px solid #E0E0E0;
  padding-top: 1rem;
  ${props => props.title && "border-top:none"};
  ${breakpoints.phone} {
       
        border-top:none;

    }
span.line{
  border-bottom:1px solid #E0E0E0;
  width: 1316px; 
  display: block;
  margin: 12px auto 0;
  ${breakpoints.darkMode} {
    [data-theme='dark'] & {
    border-bottom: 1px solid ${ColorDark.greySeparator};
    }
  }
    
    ${breakpoints.tablet} {
        width: 972px; 
        margin: 12px auto 0;
    }
    ${breakpoints.phone} {
        width: 100%; 
        padding:0 12px;
        border-top:none;

    }
  }



`

//MODULO 2 pisos
export const Parent1 = styled.div`
    ${Modulo2PisosGrid}
`;

//MODULO 4 pisos
export const Parent2 = styled.div`
    ${Modulo4PisosGrid}
`;


export const Card = styled.div`
  ${cardMixin}
  padding-bottom: 1rem;
`;

export const InnerCardL = styled.div`
  ${innerCardLefttMixin}
`;