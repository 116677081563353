import Separator from "@/shared/Separator";
import { ParentGrid, Card, InnerCardL } from "./style";
import { GridStyleColor } from "@/widgets/Container/Templates/stylesGridGeneric.js";
const Container2Pisos = (props) => {
  const { link, sectionClass = "", title, banners, titleEnabled = true, marfeelEnabled = true, title1, link1, title2, link2, title3, link3, title4, link4, linkImage } = props;
  
  const marfeelTitle = title ? "Container2Pisos-"+title?.normalize("NFD")
  .replace(/[\u0300-\u036f]/g, "")
  .replace(/\s+/g, '-')
  .toLowerCase() : "Container2Pisos";

  const titlesExtras = [];
  if(title1 && link1) titlesExtras.push({title: title1, link: link1})
  if(title2 && link2) titlesExtras.push({title: title2, link: link2})
  if(title3 && link3) titlesExtras.push({title: title3, link: link3})
  if(title4 && link4) titlesExtras.push({title: title4, link: link4})

  return (
    props.content?.length > 0 && <>
      {title && titleEnabled && <Separator title={title} link={link} sectionClass={sectionClass} extras={titlesExtras} linkImage={linkImage}/>}
      <GridStyleColor>
      <ParentGrid {...(marfeelEnabled ? {'data-mrf-recirculation': marfeelTitle} : '')} className={`Container2Pisos module-grid ${sectionClass}`}>
        <Card className="div1">
          {props.renderContainerWidgetCallback(1, props.positionProperties?.[0]?.templateNews ?? "TwoxTwoFoto", {
            containerName: "contenedor_2_pisos"
          })}
        </Card>
        <InnerCardL className="div2">
          {props.renderContainerWidgetCallback(2, props.positionProperties?.[1]?.templateNews ?? "OnexOne", {
            containerName: "contenedor_2_pisos"
          })}
        </InnerCardL>
        <InnerCardL className="div3">
          {props.renderContainerWidgetCallback(3, props.positionProperties?.[2]?.templateNews ?? "OnexOne", {
            containerName: "contenedor_2_pisos"
          })}
        </InnerCardL>
        <Card className="div4">
          {props.renderContainerWidgetCallback(4, props.positionProperties?.[3]?.templateNews ?? "OnexOne", {
            containerName: "contenedor_2_pisos"
          })}
        </Card>
        <InnerCardL className="div5">
          {props.renderContainerWidgetCallback(5, props.positionProperties?.[4]?.templateNews ?? "Half", {
            containerName: "contenedor_2_pisos"
          })}
        </InnerCardL>
        <Card className="div6">
          {props.renderContainerWidgetCallback(6, props.positionProperties?.[5]?.templateNews ?? "Half", {
            containerName: "contenedor_2_pisos"
          })}
        </Card>
      </ParentGrid>
      </GridStyleColor>
    </>
  );
};

export default Container2Pisos;