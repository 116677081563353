
import styled from "styled-components";
import { css } from "styled-components";
import { breakpoints } from "@/shared/Constants";
import { cardMixin } from "./mixin"; 


export const Modulo2PisosGrid = css`
  gap: 20px;
  margin:auto;
  width: max-content;
  grid-template-rows: inherit;
  display: grid;
  
  ${breakpoints.desktop} {
    grid-template-columns: repeat(4, 314px);
    .div1 { grid-area: 1 / 1 / 5 / 3; }
    .div2 { grid-area: 1 / 3 / 3 / 4; }
    .div3 { grid-area: 1 / 4 / 3 / 5; }
    .div4 { grid-area: 3 / 3 / 5 / 4; }
    .div5 { grid-area: 3 / 4 / 4 / 5; }
    .div6 { grid-area: 4 / 4 / 5 / 5; }
  }

  ${breakpoints.tablet} {
    grid-template-columns: repeat(3, 314px);
    .div1 { grid-area: 1 / 1 / 5 / 3; }
    .div2 { grid-area: 1 / 3 / 3 / 4; }
    .div3 { grid-area: 3 / 3 / 5 / 4; }
    .div4 { 
      grid-area: 5 / 1 / 6 / 2; ;
        height:140px;
        overflow:hidden;
          article > div{
            padding-top:0!important;
            flex-direction:row!important;
            justify-content: flex-start;
            height:100%;
            picture{
              width:120px;
              height:120px;
            }
            .mt{
              margin: 0;
              width: 60%;
              h2.title{
                overflow: hidden;
                height: 52px;
                font-size: 16px;
                line-height: 17px;
              }
              .volanta{
                font-size: 16px;
                line-height: 17px;
                height:18px;
              }
            }
          }
        }
        .div5 { grid-area: 5 / 2 / 6 / 3; }
        .div6 { grid-area: 5 / 3 / 6 / 4; }
  }

  ${breakpoints.phone} {
    max-height: inherit;
    display: flex;
    flex-direction: column;
    width: 100% ;
    padding:0 ;
  }

`;

export const Card = styled.div`
  ${cardMixin}
`;



